import {
  MetaContentFragment,
  MetaContentHead,
  MetaContentProps,
} from '@/components/common/MetaContentHead'
import { Layout } from '@/components/Layout'
import PageBlockRenderer from '@/components/pageBlocks/PageBlockRenderer'
import { fetchMenuCategories, fetchPageComponents } from '@/utils/Contentful'
import { fetchContent } from '@/utils/FetchContent'
import { TemplateHomePageComponentsCollection } from 'types/generated/contentful-types'
import React from 'react'
import { GetStaticPropsContext } from 'next'

import minify from 'gqlmin'

type HomeProps = {
  page: {
    title: string
    metaContent: MetaContentProps
    collapseHeader: boolean
  }
  components: TemplateHomePageComponentsCollection
  isPreview: boolean
  menuLinks: any
}

const Home = ({
  page,
  components,
  isPreview,
  menuLinks,
}: HomeProps): JSX.Element => {
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}`,
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '(844) 409-3672',
        contactType: 'customer service',
      },
    ],
  }

  return (
    <Layout
      showPreviewBanner={isPreview}
      collapseHeaderMargin={page.collapseHeader}
      menuLinks={menuLinks}
    >
      <MetaContentHead {...page.metaContent} jsonLd={jsonLd} />
      <PageBlockRenderer {...components} />
    </Layout>
  )
}

export async function getStaticProps({
  preview = false,
}: GetStaticPropsContext) {
  const response = await fetchContent(
    minify(`
    {
      templateHomeCollection(limit: 1, preview: ${preview}) {
        items {
          title
          ${MetaContentFragment}
          collapseHeader
        }
      }
    }
  `),
    preview
  )

  return {
    props: {
      page: response?.templateHomeCollection.items[0],
      components: await fetchPageComponents('templateHomeCollection', preview),
      isPreview: preview,
      menuLinks: await fetchMenuCategories(),
    },
  }
}

export default Home
